import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { IUserMailNotificationSettings, IUserMailSettingsData } from '../models';

@Injectable({ providedIn: 'root' })
export class LocationsService {
  constructor(private _http: HttpClient) {}

  public getLocationsList(data: any = {}) {
    // const { name } = this.state.serializeSearchData();
    const params = {
      // $top: `${top}`,
      // $count: 'true',
    };

    // if (name) {
    //   Object.assign(params, {
    //     $filter: name ? `contains(name, '${name}')` : null,
    //   });
    // }

    return this._http.get<any>(`api/locations`, { params: params });
  }

  public getLocationById(id: string) {
    return this._http.get<any>(`api/locations/${id}`);
  }
}
